import { useEffect } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import * as Chatra from '@chatra/chatra';


const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));


const BasePage = ({ children, seo, siteSetting, wagtailUserbar }) => {
	const {
		seoHtmlTitle,
		seoMetaDescription,
		seoOgTitle,
		seoOgDescription,
		seoOgUrl,
		seoOgImage,
		seoOgType,
		seoTwitterTitle,
		seoTwitterDescription,
		seoTwitterUrl,
		seoTwitterImage,
		seoMetaRobots,
		canonicalLink,
	} = seo;

	useEffect(() => {
		// if (process.env.NODE_ENV === 'production') {
		Chatra('init', { ID: 'pxTkshAwktfvSoj6B', deferredLoading: true })
		Chatra('pageView')
		// }
	}, [])

	return (
		<>
			<Head>
				<title>{seoHtmlTitle}</title>
				<meta name="robots" content={seoMetaRobots} />
			
				{!!seoMetaDescription && (
					<meta name="description" content={seoMetaDescription} />
				)}
				{!!seoOgTitle && (
					<meta property="og:title" content={seoOgTitle} />
				)}
				{!!seoOgDescription && (
					<meta property="og:description" content={seoOgDescription} />
				)}
				{!!seoOgUrl && (
					<meta property="og:url" content={seoOgUrl} />
				)}
				{!!seoOgImage && (
					<meta property="og:image" content={seoOgImage} />
				)}
				{!!seoOgType && (
					<meta property="og:type" content={seoOgType} />
				)}
				{!!seoTwitterTitle && (
					<meta property="twitter:title" content={seoTwitterTitle} />
				)}
				{!!seoTwitterDescription && (
					<meta property="twitter:description" content={seoTwitterDescription} />
				)}
				{!!seoTwitterUrl && (
					<meta property="twitter:url" content={seoTwitterUrl} />
				)}
				{!!seoTwitterImage && (
					<meta property="twitter:image" content={seoTwitterImage} />
				)}
				{!!canonicalLink && (
					<link rel="canonical" href={canonicalLink} />
				)}
			</Head>
			
			<main>
				<Header siteSetting={siteSetting} />
				{children}
				<Footer siteSetting={siteSetting} />
			</main>

			{!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
		</>
	);
};
		
		
		BasePage.defaultProps = {
			seo: {},
			siteSetting: {}
		};
		
		BasePage.propTypes = {
			children: PropTypes.node,
			seo: PropTypes.shape({
				seoHtmlTitle: PropTypes.string,
				seoMetaDescription: PropTypes.string,
				seoOgTitle: PropTypes.string,
				seoOgDescription: PropTypes.string,
				seoOgUrl: PropTypes.string,
				seoTwitterTitle: PropTypes.string,
				seoMetaRobots: PropTypes.string,
			}),
			siteSetting: PropTypes.shape({
				email: PropTypes.string,
				phone: PropTypes.shape({
					international: PropTypes.string,
					rfc3966: PropTypes.string
				}),
				privacyPolicyPage: PropTypes.shape({
					slug: PropTypes.string
				}),
				refundPolicyPage: PropTypes.shape({
					slug: PropTypes.string
				}),
				warrantyPolicyPage: PropTypes.shape({
					slug: PropTypes.string
				}),
				termsConditionsPage: PropTypes.shape({
					slug: PropTypes.string
				})
			}),
			wagtailUserbar: PropTypes.shape({
				html: PropTypes.string,
			}),
		};
		
		export default BasePage;
		