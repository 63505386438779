import Link from 'next/link';
import { trackWhatsAppClick } from '../../utils/analytics';

// import PropTypes from 'prop-types';

import logo from '../../public/img/logo.png'
import WhatappSvg from '../../public/svg/whatsapp.svg'
// import InstagramSvg from '../../public/svg/instagram.svg'
// import EvgmedSvg from '../../public/svg/evgmed.svg'


function Footer({ siteSetting }) {
  return (
    <footer className="w-full sm:mt-vw-16 mt-vw-36">
      <div className="container-main">
        <svg className="desktop-only w-full" viewBox="0 0 1312 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0L656 3.5L1312 0V1H656H0V0Z" fill="black" />
        </svg>
        <svg className="mobile-only w-full" viewBox="0 0 352 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.835938 0.166016L176.418 4.16602L352.001 0.166016V1.30887H176.418H0.835938V0.166016Z" fill="black" />
        </svg>

        <div className="flex sm:flex-row flex-col-reverse centered-y w-full sm:h-vw-28 sm:py-0 py-vw-24">
          <Link aria-label="Home" href="/" className="flex-shrink-0 sm:mt-0 mt-vw-24 order-1">
            <img className="sm:w-vw-24 w-vw-48" src={logo.src} alt="" />
          </Link>
          <div className='flex flex-col flex-grow justify-center items-center order-3'>
            <div className="flex sm:flex-row flex-col flex-grow sm:justify-center items-center sm:flex-wrap">
              <a
                href={`mailto:${siteSetting.email}`}
                aria-label="Email" 
                className="link-main sm:text-vw-base text-vw-4xl hover:underline sm:mr-vw-12 sm:mt-0 mt-vw-12"
              >
                {siteSetting.email}
              </a>

              {siteSetting.instagramUrl && (
                <a
                  href={siteSetting.instagramUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Instagram" 
                  className="link-main sm:text-vw-base text-vw-4xl hover:underline sm:mr-vw-12 sm:mt-0 mt-vw-12"
                >
                  instagram
                </a>
              )}

              {siteSetting.telegramUrl && (
                <a
                  href={siteSetting.telegramUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Telegram" 
                  className="link-main sm:text-vw-base text-vw-4xl hover:underline sm:mr-vw-12 sm:mt-0 mt-vw-12"
                >
                  telegram
                </a>
              )}

              <div className="sm:text-vw-base text-vw-4xl sm:mt-0 mt-vw-12">
                <a aria-label="Phone" href={siteSetting.phone.rfc3966} className="hover:underline">
                  {siteSetting.phone.international}
                </a>
              </div>

              {(siteSetting.whatsappUrl || siteSetting.instagramUrl) && (
                <div className="sm:text-vw-base text-vw-4xl sm:mt-0 mt-vw-12 flex items-center">
                  {siteSetting.whatsappUrl && (
                    <a
                      href={
                        siteSetting.whatsappText 
                          ? `${siteSetting.whatsappUrl}?text=${encodeURIComponent(siteSetting.whatsappText)}`
                          : siteSetting.whatsappUrl
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                      aria-label="WhatsApp"
                      onClick={trackWhatsAppClick}
                      className="inline-block sm:ml-vw-8 -mb-vw-.5"
                    >
                      <WhatappSvg className="sm:w-vw-6 w-vw-24" />
                    </a>
                  )}

                  {/* {siteSetting.instagramUrl && (
                    <a
                      href={siteSetting.instagramUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                      aria-label="Instagram" 
                      className="inline-block sm:ml-vw-8 ml-vw-16 -mb-vw-1.5"
                    >
                      <InstagramSvg id="footer1" className="sm:w-vw-6 w-vw-24" />
                    </a>
                  )} */}
                  </div>
                )}
            </div>
            <div className="flex sm:flex-row flex-col flex-grow sm:justify-center items-center sm:flex-wrap mt-4">
              {siteSetting.privacyPolicyPage && <Link
                href={siteSetting.privacyPolicyPage.slug}
                aria-label="Privacy Policy" 
                className="link-main sm:text-vw-base text-vw-4xl hover:underline sm:mr-vw-12"
              >
                privacy policy
              </Link>}
              {siteSetting.refundPolicyPage && <Link
                href={siteSetting.refundPolicyPage.slug}
                className="link-main sm:text-vw-base text-vw-4xl hover:underline sm:mr-vw-12 sm:mt-0 mt-vw-12"
              >
                refund policy
              </Link>}
              {siteSetting.warrantyPolicyPage && <Link
                href={siteSetting.warrantyPolicyPage.slug}
                aria-label="Warranty Policy" 
                className="link-main sm:text-vw-base text-vw-4xl hover:underline sm:mr-vw-12 sm:mt-0 mt-vw-12"
              >
                warranty policy
              </Link>}
              {siteSetting.termsConditionsPage && <Link
                href={siteSetting.termsConditionsPage.slug}
                className="link-main sm:text-vw-base text-vw-4xl hover:underline sm:mr-vw-12 sm:mt-0 mt-vw-12"
              >
                terms & conditions
              </Link>}
              
              {siteSetting.legalEntity && (
                <p className="w-full sm:text-vw-base text-vw-4xl sm:mr-vw-12 sm:mt-vw-6 mt-vw-12 text-center">{siteSetting.legalEntity}</p>
                )}
              </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {};

Footer.defaultProps = {};


export default Footer;
