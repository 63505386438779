import { useContext } from 'react';
import NextLink from 'next/link';
import { UtmContext } from '../../contexts/UtmContext';

const Link = ({ href, children, ...props }) => {
  const utmParams = useContext(UtmContext);

  const hrefWithQuery = `${href}${utmParams}`;

  return (
    <NextLink href={hrefWithQuery} {...props}>
      {children}
    </NextLink>
  );
};

export default Link;